import Typography from "typography";
import 'typeface-roboto';

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.666,
  headerFontFamily: ["roboto"],
  bodyFontFamily: ["roboto"],
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
