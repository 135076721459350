import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { rhythm } from "../../utils/typography";
import './blog-post.scss';

const BlogPostTemplate = ({ data, pageContext, location, cover }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext;


  const image_cover = (post.frontmatter.cover !== null) ? post.frontmatter.cover.publicURL : data.cover_default.childImageSharp.fixed.src;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        cover={ image_cover }
      />
      <article>
        <header className="header-post">
          <div className="container-md">
            <div className="row">
              <div className="col-12 mt-5 text-left pl-5">
                <h1 className="mb-0 pb-0">
                  {post.frontmatter.title}
                </h1>
                <div className="post-tags">
                  {post.frontmatter.tags &&
                    post.frontmatter.tags.map((tag, i, tags) => {
                      return (<React.Fragment key={i}><Link to={`/tag/${tag}/`} className="text-capitalize">{tag}</Link>{(i !== tags.length - 1) ? ', ' : ''} </React.Fragment>)
                    })
                  }
                </div>
                <p>
                  {post.frontmatter.date}
                </p>
              </div>
            </div>
          </div>
        </header>
        <div className="container-md pl-0 pr-0">
          <section className="post-body" dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </article>
      <nav className="container after-before--links">
        <ul className="row">

          <li className="col-6">
            {previous && (
              <Link to={previous.node.fields.slug} rel="prev">
                ← {previous.node.frontmatter.title}
              </Link>
            )}
          </li>
          <li className="col-6 text-right">
            {
              next && (
                <Link to={next.node.fields.slug} rel="next" className="text-white-color">
                  {next.node.frontmatter.title} →
              </Link>)
            }
          </li>

        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    cover_default: file(absolutePath: { regex: "/cover.png/"}) {
      childImageSharp {
          fixed(width: 320, height: 180) {
              ...GatsbyImageSharpFixed
          }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        description
        tags
        cover {
          publicURL
        }
      }
    }
  }
`
